import { getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Get Firebase default services
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const analytics = getAnalytics(app)

const functions = (region = 'europe-west1') => getFunctions(app, region)

// Get the custom storages
const clientThemesRootStorage = getStorage(app, process.env.VUE_APP_FIREBASE_CLIENT_THEMES_STORAGE_BUCKET)

// Get the custom databases
const themesDb = getFirestore(app, process.env.VUE_APP_FIRESTORE_THEMES_DB_ID)

// For development purpose.
// I know, 'true' seems nasty, but it has good intentions.
if (process.env.VUE_APP_FIREBASE_EMULATOR === 'true') {
  connectFunctionsEmulator(functions(), 'localhost', 5001)
  // connectFirestoreEmulator(db, 'localhost', 3001)
}

export {
  db,
  auth,
  storage,
  themesDb,
  logEvent,
  analytics,
  functions,
  clientThemesRootStorage,
}
